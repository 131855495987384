import { Box } from 'reflexbox'

const layoutTheme = {
    breakpoints: ['360px', '728px', '1024px', '1280px', '1600px'],
    variants: {
        gallerySliderTitle: {
            px: [20, null, null, null, null, 0],
            mx: 'auto',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            mb: [null, null, 60]
        },

        addBorderRadius: {
            borderRadius: 5,
            overflow: 'hidden'
        },

        spaceBetweenLastRow: {
            '&:after': {
                content: '""',
                width: 'calc(33.3333% - 20px)'
            }
        },

        mapTitle: {
            px: '20px',
            mx: 'auto',
            maxWidth: 1600,
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            mb: [null, null, 20]
        },

        blockTitle: {
            px: '20px',
            mx: 'auto',
            maxWidth: 1360
        },

        mapAddress: {
            display: ['none', null, 'block'],
            mb: [null, null, null, 20],
            minWidth: [300, null, null, 400],
            textAlign: [null, null, 'right']
        },

        px20: {
            px: 20
        },

        center1280: {
            mx: 'auto',
            maxWidth: 1280
        },
        center1300: {
            mx: 'auto',
            maxWidth: 1300
        },
        center1340: {
            mx: 'auto',
            maxWidth: 1340
        },
        center1360: {
            mx: 'auto',
            maxWidth: 1360
        },
        center1380: {
            mx: 'auto',
            maxWidth: 1380
        },
        center1600: {
            mx: 'auto',
            maxWidth: 1600
        },
        center1640: {
            mx: 'auto',
            maxWidth: 1640
        },
        center1680: {
            mx: 'auto',
            maxWidth: 1680
        }
    }
}

export default layoutTheme
